<template>
  <div>
    <div class="row mb-3">
      <div class="col">
        <button v-on:click="goBack()" class="btn" v-bind:class="darkModeBtn">Go Back</button>
      </div>
      <div v-if="showAdd" class="col d-flex justify-content-end">
        <router-link active-class="r-active" tag="button" to="/account/cards/add" class="btn" v-bind:class="darkModeBtn">Add Card</router-link>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { darkModeStyle } from '../../../assets/js/darkMode';

export default {
  name: 'AccountCardMain',
  data() {
    return {
      showAdd: true,
      darkMode: darkModeStyle('bg-dark text-light', 'bg-light text-dark'),
      darkModeText: darkModeStyle('text-light', 'text-dark'),
      darkModeBtn: darkModeStyle('btn-dark', 'btn-light'),
    }
  },
  methods: {
    goBack() {
      const path = this.$route.path.split('/');
      if (path[path.length - 1] === 'add') {
        this.$router.push('/account/cards');
      } else {
        this.$router.push('/account');
      }
    }
  },
  watch: {
    '$route'(to, from) {
      if (to.path === '/account/cards/add') {
        this.showAdd = false;
      } else {
        this.showAdd = true;
      }
    }
  },
  mounted() {
    if (this.$route.path === '/account/cards/add') {
      this.showAdd = false;
    }
  },
}
</script>